const slugify = require("slugify")
const { slugifyDateTime } = require("./datetime")

slugify.extend({ ".": "-" })

const getAppointmentSlug = (startdate, title) => {
  return `/termine/${slugifyDateTime(startdate)}-${slugify(title, {
    lower: true,
  })}`
}

module.exports = {
  getAppointmentSlug,
}
