import React from "react"
import { graphql } from "gatsby"
import { UList } from "./blocks"
import { LocationName } from "./location.jsx"
import TagIcon from "../../content/assets/zondicons/tag.svg"
import InformationOutline from "../../content/assets/zondicons/information-outline.svg"
import PersonsIcon from "../../content/assets/zondicons/user-group.svg"

import "moment/locale/de"
import { DateTime } from "./datetime"
import { LinkItem } from "./customlink"
import { getAppointmentSlug } from "../utils/slug"

export const AppointmentHeader = ({ appointment }) => (
  <div>
    <div className="text-primary-600">
      <DateTime start={appointment.start} end={appointment.end} />
    </div>
    {appointment.tags ? (
      <div className="flex items-start text-primary-600 fill-current mt-2">
        <TagIcon className="h-4 w-4 mr-4" />
        <UList
          items={appointment.tags}
          getKey={tag => tag}
          renderItem={tag => <AppointmentTypeItem type={tag} />}
        />
      </div>
    ) : (
      ""
    )}
    <div className="flex items-start text-primary-600 fill-current mt-2">
      <InformationOutline className="h-4 w-4 mr-4" />
      <UList
        items={appointment.types}
        getKey={type => type}
        renderItem={type => <AppointmentTypeItem type={type} />}
      />
    </div>
  </div>
)

export const AppointmentOrganizer = ({ organizer }) =>
  organizer && organizer.length > 0 ? (
    <div className="flex items-start text-primary-600 fill-current mt-2">
      <PersonsIcon className="h-4 w-4 mr-4" />
      <UList
        items={organizer}
        getKey={o => (o ? o.name : "null")}
        renderItem={o =>
          o ? (
            <div>
              {o.firstname} {o.lastname || ""}
            </div>
          ) : (
            "Information zum Veranstalter nicht verfügbar"
          )
        }
      />
    </div>
  ) : (
    " "
  )

export const AppointmentFooter = ({ appointment }) => (
  <div>
    <div className="clearfix my-2 text-primary-600 fill-current">
      <UList
        items={appointment.locations}
        getKey={loc => loc.name}
        renderItem={loc => <LocationName location={loc} />}
      />
    </div>
    <AppointmentOrganizer organizer={appointment.organizer} />
  </div>
)

export const AppointmentItem = ({ appointment, path }) => (
  <LinkItem
    to={path}
    header={<AppointmentHeader appointment={appointment} />}
    main={appointment.title}
    footer={<AppointmentFooter appointment={appointment} />}
  />
)

export const AppointmentTypeItem = ({ type }) => (
  <div className="text-sm tk-font-bold text-primary-500">{type}</div>
)

export const AppointmentTypeList = ({ types, item }) => (
  <ul>
    {types.map(type => (
      <li key={type}>{item(type)}</li>
    ))}
  </ul>
)

export const AppointmentIndex = ({
  appointments,
  skip = 0,
  limit = 9999,
  filter,
  className = "",
}) =>
  appointments
    .filter(({ node: a }) => filter(a))
    .slice(skip, skip + limit)
    .map(({ node: appointment }) => {
      return (
        <div key={appointment.fields.slug} className={className}>
          <AppointmentItem
            appointment={appointment.frontmatter}
            path={getAppointmentSlug(
              appointment.frontmatter.start,
              appointment.frontmatter.title
            )}
          />
        </div>
      )
    })

export const query = graphql`
  fragment AppointmentInformation on MarkdownRemark {
    fields {
      slug
      # path: mypath
    }
    excerpt(pruneLength: 220)
    frontmatter {
      types
      title
      organizer {
        name
        firstname
        lastname
      }
      tags
      # slugdate: start(formatString: "YYYY-MM-DD-HH-mm")
      start # (formatString: "dddd, D. MMMM YYYY, HH:mm", locale: "de-DE")
      end # (formatString: "dddd, D. MMMM YYYY, HH:mm", locale: "de-DE")
      locations {
        ...LocationInformation
      }
      archiveAt
      onFrontpageUntil
      state
    }
    htmlAst
  }
`
