import React from "react"
import { graphql } from "gatsby"
import LocationIcon from "../../content/assets/zondicons/location.svg"
import { IconBox } from "./blocks"

const googleAPI = "https://www.google.com/maps/search/?api=1&query="

export const LocationName = ({ location }) => (
  <IconBox icon={<LocationIcon className="h-4 float-left mr-2" />}>
    <span className="">{location.name}</span>
  </IconBox>
)

/*
export const LocationName2 = ({ location }) => (
  <div className="inline-block text-sm h-6 align-text-middle text-primary-600 inline-block fill-current ">
    <LocationIcon className="h-4 float-left mr-2" />
    <span className="h-full">{location.name}</span>
  </div>
)
*/

let normalizer = arr =>
  arr
    .join("+")
    .replace(/[„“]/g, "")
    .replace(/[&]/g, "und")
    .replace(/[ ]/g, "+")

export const Location = ({ location }) => (
  <a
    href={
      googleAPI +
      normalizer([
        location.name,
        location.street,
        location.zipcode,
        location.city,
      ])
    }
  >
    <IconBox icon={<LocationIcon className="h-4 float-left mr-2" />}>
      <div className="text-primary-500 text-lg">
        <div className="underline inline-block">{location.name},&nbsp;</div>
        {location.street ? (
          <div className="underline inline-block">{location.street},&nbsp;</div>
        ) : (
          <div className="inline-block" />
        )}
        <div className="inline-block">
          <div className="underline inline-block">{location.zipcode}&nbsp;</div>
          <div className="underline inline-block">{location.city}</div>
        </div>
      </div>
    </IconBox>
  </a>
)

export const LocationList = ({ locations }) => (
  <ul>
    {locations.map(location => (
      <li key={location.name}>
        <LocationName location={location} />
      </li>
    ))}
  </ul>
)

export const query = graphql`
  fragment LocationInformation on LocationsYaml {
    name
    map
    city
    street
    zipcode
    map
  }
`
