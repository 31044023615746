import React from "react"
import { graphql } from "gatsby"
import { renderAst } from "../utils/markdown.jsx"
import { H1, PX3, Max5xl, Max2xl, UList } from "../components/blocks"
import TagIcon from "../../content/assets/zondicons/tag.svg"
import InformationOutline from "../../content/assets/zondicons/information-outline.svg"

// import PersonsIcon from "../../content/assets/zondicons/user-group.svg"
import Layout from "../components/layout.jsx"
import SEO from "../components/seo.js"
import { Location } from "../components/location.jsx"
import { DateTime } from "../components/datetime.jsx"
import {
  AppointmentTypeList,
  AppointmentTypeItem,
  AppointmentOrganizer,
} from "../components/appointment.jsx"
import { formatDateTime } from "../utils/datetime.js"
// import Appointment from "../components/appointment.jsx"

class AppointmentPageTemplate extends React.Component {
  render() {
    const data = this.props.data
    const post = data.markdownRemark
    const siteTitle = data.site.siteMetadata.title
    const frontmatter = post.frontmatter
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <div className="text-primary-900 h-full">
          <SEO
            title={`${post.frontmatter.title} am ${formatDateTime(
              post.frontmatter.start
            )}`}
            description={post.excerpt}
          />

          <Max5xl className="h-full">
            <Max2xl>
              <PX3>
                <div className="pt-8 text-primary-600">
                  <DateTime start={frontmatter.start} end={frontmatter.end} />
                  <div className="mt-2 text-primary-600">
                    <UList
                      items={frontmatter.locations}
                      getKey={location => location.name}
                      renderItem={location => <Location location={location} />}
                    />
                  </div>
                  <AppointmentOrganizer organizer={frontmatter.organizer} />

                  {frontmatter.tags ? (
                    <div className="flex mt-2 items-start text-primary-600 fill-current">
                      <TagIcon className="h-4 w-4 mr-4" />
                      <AppointmentTypeList
                        types={frontmatter.tags}
                        item={tag => <AppointmentTypeItem type={tag} />}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex mt-2 items-start text-primary-600 fill-current">
                    <InformationOutline className="h-4 w-4 mr-4" />
                    <AppointmentTypeList
                      types={frontmatter.types}
                      item={type => <AppointmentTypeItem type={type} />}
                    />
                  </div>
                </div>
                <H1 margins="pt-3">{frontmatter.title}</H1>
              </PX3>
              <PX3>
                <div className="pt-3 text-primary-800 ">
                  {renderAst(post.htmlAst)}
                </div>
              </PX3>
              {/* <MyMap position={[8.7703947, 50.8091699]} /> */}
            </Max2xl>
          </Max5xl>
        </div>
      </Layout>
    )
  }
}

export default AppointmentPageTemplate

export const appointmentPageQuery = graphql`
  query AppointmentPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...AppointmentInformation
    }
  }
`
